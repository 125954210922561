<template>
  <div class="login-container">
    <BaseNotification
      v-for="notification in notifications"
      :key="notification.id"
      :notification="notification"
    />
    <div class="login-body">
      <h1>Login</h1>

      <form>
        <div v-for="input in inputs" :key="input.index">
          <BaseInput
            :placeholder="input.placeholder"
            :name="input.name"
            :id="input.id"
            :type="input.type"
            :value="input.value"
            :index="input.index"
            :message="input.message"
            @updatedValue="updateValue"
          />
        </div>
      </form>
      <BaseButton
        :disabled="disableLogin"
        @buttonPressed="
          login({ email: inputs[0].value, password: inputs[1].value })
        "
      >
        Login
      </BaseButton>
      <div class="forgot-password-link">
        <router-link to="/auth/forgotpassword"
          >Wachtwoord vergeten?</router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import BaseButton from "@/components/BaseButton.vue";
import BaseInput from "@/components/BaseInput";
import BaseNotification from "@/components/BaseNotification.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "Login",
  components: {
    BaseButton,
    BaseInput,
    BaseNotification,
  },
  data() {
    return {
      inputs: [
        {
          placeholder: "e-mailadres",
          name: "email",
          id: "email",
          type: "email",
          value: "",
          message: null,
        },
        {
          placeholder: "wachtwoord",
          name: "password",
          id: "password",
          type: "password",
          value: "",
          message: null,
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      login: "auth/login",
      checkLoggedIn: "auth/checkLoggedIn",
    }),
    updateValue(params) {
      this.inputs.forEach((input) => {
        if (input.id === params.id) {
          input.value = params.value;
        }
      });
      this.validateInputs();
    },
    validateInputs() {
      if (this.inputs[0].value === "") {
        this.inputs[0].message = { text: "Verplicht veld", type: "error" };
      } else {
        this.inputs[0].message = null;
      }

      if (this.inputs[1].value === "") {
        this.inputs[1].message = { text: "Verplicht veld", type: "error" };
      } else {
        this.inputs[1].message = null;
      }
    },
  },
  computed: {
    ...mapGetters({
      notifications: "notification/notifications",
    }),
    disableLogin() {
      if (this.inputs[0].value === "" || this.inputs[1].value === "") {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    this.validateInputs();
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.login-container {
  padding-top: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  .notification-container {
    width: 100%;
    max-width: 600px;
    display: flex;
    justify-content: center;
  }
  .login-body {
    width: 100%;
    max-width: 600px;
    h1 {
      text-align: center;
      padding-bottom: calc(#{$standard-padding} * 2);
    }

    .input-container {
      margin-bottom: $standard-margin;
    }

    button {
      margin-left: auto;
      margin-right: auto;
      margin-top: $standard-margin;
      margin-bottom: $standard-padding;
    }
    .forgot-password-link {
      padding-left: $standard-padding;
      padding-right: $standard-padding;
      display: flex;
      justify-content: center;

      a {
        text-decoration: none;
        color: $lm-link-color;
        transition: 0.1s color;
      }

      a:hover {
        color: darken($lm-link-color, 30%);
      }
    }
  }
}
@media (min-width: $sm-screen) {
  .login-body {
    padding-left: 20vw;
    padding-right: 20vw;
  }
}
</style>
